import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'landing-page',
    component: () => import('@/views/landing-page/Index.vue'),
    meta: { title: 'About Pinkapple' },
  },
  {
    path: '/c/:alias',
    component: () => import('@/layouts/ParentLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'login' },
      },
      {
        path: 'auth',
        name: 'auth',
        component: () => import('@/layouts/AuthLayout.vue'),
        children: [
          {
            path: '',
            redirect: { name: 'login' },
          },
          {
            path: 'login',
            name: 'login',
            component: () => import('@/views/auth/Login.vue'),
            meta: { title: 'Login to Pinkapple' },
          },
          {
            path: 'register',
            name: 'register',
            component: () => import('@/views/auth/Signup.vue'),
            meta: { title: 'Register User Pinkapple' },
          },
        ],
      },
      {
        path: '',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'dashboard',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Dashboard' },
            children: [
              {
                path: '',
                redirect: { name: 'generaldashboard' },
              },
              {
                path: 'generaldashboard',
                name: 'generaldashboard',
                component: () => import('@/views/dashboard/Index.vue'),
                meta: { title: 'Pinkapple general dashboard' },
              },
              {
                path: 'tellerdashboard',
                name: 'tellerdashboard',
                component: () => import('@/views/dashboard/teller.vue'),
                meta: { title: 'Pinkapple teller dashboard' },
              },
              {
                path: 'loanofficerdashboard',
                name: 'loanofficerdashboard',
                component: () => import('@/views/dashboard/loanOfficer.vue'),
                meta: { title: 'Pinkapple loan officer dashboard' },
              },
            ],
          },
          {
            path: 'administration',
            name: 'administration',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Administration' },
            children: [
              {
                path: '',
                redirect: { name: 'general_setup' },
              },
              {
                path: 'general_setup',
                name: 'general_setup',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration  - General Setup' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'orgnisation_details' },
                  },
                  {
                    path: 'orgnisation_details',
                    name: 'orgnisation_details',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple General Setup - ORGANISATION DETAILS',
                    },
                  },
                  {
                    path: 'holidays',
                    name: 'general-setup-holidays',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple General Setup - HOLIDAYS' },
                  },
                  {
                    path: 'client_type',
                    name: 'client_type',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple General Setup - CLIENT TYPE' },
                  },
                  {
                    path: 'business_unit_type',
                    name: 'business_unit_type',
                    component: () =>
                      import('@/views/admin/BusinessUnitTypes.vue'),
                    meta: {
                      title: 'Pinkapple General Setup - BUSINESS UNIT TYPE',
                    },
                  },
                  {
                    path: 'id_templates',
                    name: 'id_templates',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple General Setup - ID TEMPLATES' },
                  },
                  {
                    path: 'internal',
                    name: 'internal',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple General Setup - INTERNAL' },
                  },
                  {
                    path: 'labels',
                    name: 'labels',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple General Setup - LABELS' },
                  },
                  {
                    path: 'group_roles',
                    name: 'group_roles',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple General Setup - GROUP ROLES' },
                  },
                  {
                    path: 'branding',
                    name: 'branding',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple General Setup - BRANDING' },
                  },
                ],
              },
              {
                path: 'financial_setup',
                name: 'financial_setup',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration  - Financial Setup' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'transaction_channels' },
                  },
                  {
                    path: 'transaction_channels',
                    name: 'transaction_channels',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - TRANSACTION CHANNELS',
                    },
                  },
                  {
                    path: 'exchange_rates',
                    name: 'exchange_rates',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - EXCHANGE RATES',
                    },
                  },
                  {
                    path: 'eod_processing',
                    name: 'eod_processing',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - EOD PROCESSING',
                    },
                  },
                  {
                    path: 'authorisation_holds',
                    name: 'authorisation_holds',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - AUTHORISATION HOLDS',
                    },
                  },
                  {
                    path: 'rates',
                    name: 'rates',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Administration - RATES' },
                  },
                  {
                    path: 'currency',
                    name: 'setup-currency',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Administration - CURRENCY' },
                  },
                  {
                    path: 'risk_levels',
                    name: 'risk_levels',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Administration - RISK LEVELS' },
                  },
                  {
                    path: 'accounting',
                    name: 'accounting',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Administration - ACCOUNTING' },
                  },
                ],
              },
              {
                path: 'organisation',
                name: 'organisation',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration  - Organisation' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'business_unit' },
                  },
                  {
                    path: 'business_unit',
                    name: 'business_unit',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration -  Business Units',
                    },
                  },
                ],
              },
              {
                path: 'products',
                name: 'products',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration  - Products' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'loan_products' },
                  },
                  {
                    path: 'loan_products',
                    name: 'loan_products',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Loan Products' },
                  },
                  {
                    path: 'deposit_products',
                    name: 'deposit_products',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Deposit Products' },
                  },
                ],
              },
              {
                path: 'apps',
                name: 'apps',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration - Apps' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'app' },
                  },
                  {
                    path: 'app',
                    name: 'app',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Products - LOAN PRODUCTS' },
                  },
                ],
              },
              {
                path: 'fields',
                name: 'administration-fields',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration - Fields' },
                children: [
                  {
                    path: '',
                    redirect: {
                      name: 'clients_field_controler',
                    },
                  },
                  {
                    path: 'clients_field_controler',
                    name: 'clients_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'groups_field_controler',
                    name: 'groups_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'credit_arrangements_field_controler',
                    name: 'credit_arrangements_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'loan_accounts_field_controler',
                    name: 'loan_accounts_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'deposit_accounts_field_controler',
                    name: 'deposit_accounts_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'guarantors_field_controler',
                    name: 'guarantors_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'assets_field_controler',
                    name: 'assets_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'hr_field_controler',
                    name: 'hr_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'shares_field_controler',
                    name: 'shares_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'deposit_products_field_controler',
                    name: 'deposit_products_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'transaction_by_channel_field_controler',
                    name: 'transaction_by_channel_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'transaction_by_type_field_controler',
                    name: 'transaction_by_type_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                  {
                    path: 'users_field_controler',
                    name: 'users_field_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Administration - Fields',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'access',
            name: 'access',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Access' },
            children: [
              {
                path: '',
                redirect: { name: 'access-users' },
              },
              {
                path: 'users',
                name: 'access-users',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Access - Users' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'user_controler' },
                  },
                  {
                    path: 'user_controler',
                    name: 'user_controler',
                    component: () => import('@/views/access/roles/Index.vue'),
                    meta: { title: 'Pinkapple Access - Users' },
                  },
                  {
                    path: 'user_preferences_controler',
                    name: 'user_preferences_controler',
                    component: () => import('@/views/access/roles/Index.vue'),
                    meta: { title: 'Pinkapple Access - Users' },
                  },
                  {
                    path: 'approval_levels_controler',
                    name: 'approval_levels_controler',
                    component: () => import('@/views/access/roles/Index.vue'),
                    meta: { title: 'Pinkapple Access - Users' },
                  },
                ],
              },
              {
                path: 'roles',
                name: 'access-roles',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Access - Roles' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'user_roles_controler' },
                  },
                  {
                    path: 'user_roles_controler',
                    name: 'user_roles_controler',
                    component: () => import('@/views/access/roles/Index.vue'),
                    meta: { title: 'Pinkapple Access - Users - Roles' },
                  },
                ],
              },
            ],
          },
          {
            path: 'communication',
            name: 'communication',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Communication' },
            children: [
              {
                path: '',
                redirect: { name: 'templates' },
              },
              {
                path: 'templates',
                name: 'templates',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Communication - Templates' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'templates_controler' },
                  },
                  {
                    path: 'templates_controler',
                    name: 'templates_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Communication - Templates',
                    },
                  },
                ],
              },
              {
                path: 'sms',
                name: 'communication-sms',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Communication - SMS' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'sms_controler' },
                  },
                  {
                    path: 'sms_controler',
                    name: 'sms_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Communication - SMS',
                    },
                  },
                ],
              },
              {
                path: 'email',
                name: 'communication-email',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Communication - Email' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'email_controler' },
                  },
                  {
                    path: 'email_controler',
                    name: 'email_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: {
                      title: 'Pinkapple Communication - Emails',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'clients',
            name: 'clients',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Clients' },
            children: [
              {
                path: '',
                redirect: { name: 'individual_clients' },
              },
              {
                path: 'groups',
                name: 'client_groups',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Client Groups' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'groups_controler' },
                  },
                  {
                    path: 'groups_controler',
                    name: 'groups_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Clients Groups' },
                  },
                ],
              },
              {
                path: 'individual_clients',
                name: 'individual_clients',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Individual Clients' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'individual_clients_controler' },
                  },
                  {
                    path: 'individual_clients_controler',
                    name: 'individual_clients_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Individual Clients' },
                  },
                ],
              },
            ],
          },
          {
            path: 'deposit_accounts',
            name: 'deposit_accounts',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Deposit Accounts' },
            children: [
              {
                path: '',
                redirect: { name: 'deposit_accounts_definer_' },
              },
              {
                path: 'deposit_accounts_definer_',
                name: 'deposit_accounts_definer_',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Deposit Accounts' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'deposit_accounts_controler' },
                  },
                  {
                    path: 'deposit_accounts_controler',
                    name: 'deposit_accounts_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Deposit Accounts' },
                  },
                ],
              },
            ],
          },
          {
            path: 'loan_accounts',
            name: 'loan_accounts',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Loan Accounts' },
            children: [
              {
                path: '',
                redirect: { name: 'loan_accounts_definer' },
              },
              {
                path: 'loan_accounts_definer',
                name: 'loan_accounts_definer',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Loan Accounts' },
                children: [
                  {
                    path: '',
                    redirect: {
                      name: 'loan_accounts_controler',
                    },
                  },
                  {
                    path: 'loan_accounts_controler',
                    name: 'loan_accounts_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Loan Accounts' },
                  },
                ],
              },
            ],
          },
          {
            path: 'credit_arrangements',
            name: 'credit_arrangements',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Credit Arrangements' },
            children: [
              {
                path: '',
                redirect: { name: 'credit_arrangements_definer' },
              },
              {
                path: 'credit_arrangements_definer',
                name: 'credit_arrangements_definer',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Credit Arrangements' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'credit_arrangements_controler' },
                  },
                  {
                    path: 'credit_arrangements_controler',
                    name: 'credit_arrangements_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Credit Arrangements' },
                  },
                ],
              },
            ],
          },
          {
            path: 'shares',
            name: 'shares',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Shares' },
            children: [
              {
                path: '',
                redirect: { name: 'shares_management_definer' },
              },
              {
                path: 'shares_management_definer',
                name: 'shares_management_definer',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Shares Management' },
                children: [
                  {
                    path: '',
                    redirect: {
                      name: 'shares_management_controler',
                    },
                  },
                  {
                    path: 'shares_management_controler',
                    name: 'shares_management_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Shares Management' },
                  },
                ],
              },
            ],
          },
          {
            path: 'assets',
            name: 'assets',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Assets' },
            children: [
              {
                path: '',
                redirect: { name: 'asset_management_definer' },
              },
              {
                path: 'asset_management_definer',
                name: 'asset_management_definer',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Assets Management' },
                children: [
                  {
                    path: '',
                    redirect: {
                      name: 'asset_management_controler',
                    },
                  },
                  {
                    path: 'asset_management_controler',
                    name: 'asset_management_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple Assets Management' },
                  },
                ],
              },
            ],
          },
          {
            path: 'hr',
            name: 'hr',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple HR' },
            children: [
              {
                path: '',
                redirect: { name: 'hr_management_definer' },
              },
              {
                path: 'hr_management_definer',
                name: 'hr_management_definer',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple HR' },
                children: [
                  {
                    path: '',
                    redirect: { name: 'hr_management_controler' },
                  },
                  {
                    path: 'hr_management_controler',
                    name: 'hr_management_controler',
                    component: () => import('@/layouts/ParentLayout.vue'),
                    meta: { title: 'Pinkapple HR' },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/NotFoundPage.vue'),
    name: 'Not-found-Page',
  },
];

export default routes;
